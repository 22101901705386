import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, BorderedHeader } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import BorderedHeaderWrapper from "../components/borderedHeaderWrapper"
import styled from "styled-components"
import { BigMobileFont } from "../components/typography"
import JobOfferForm from "../components/jobOfferForm"
import LastCTASection from "../components/lastCTASection"
import { useStaticQuery, graphql } from "gatsby"
import ArrowMore from "../components/arrowMore"
import BaseImg from "../components/baseImg"

const JobInfoBox = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  padding: 36px 52px;
  background: #fff;
  margin: -110px auto 0;
  z-index: 1;
  position: relative;
  font-size: 1.5rem;
  text-align: center;
  max-width: 784px;
  @media (max-width: 768px) {
    margin-top: -10%;
    line-height: 1.5;
    padding: 2rem 1rem;
  }
`

const WingsImage = styled.img`
  position: absolute;
  top: -20px;
  ${(props) => (props.side === "right" ? "right" : "left")}: 0;
  transform: ${(props) => (props.side === "right" ? "scaleX(-1)" : "")};
  width: calc(50vw - 485px);
  @media (max-width: 1195px) {
    display: none;
  }
`

const StyledList = styled.ul`
  margin: 52px 0 88px 20px;
  font: 400 1.125rem/1.625rem "Hind";
`

const SingleCareer = ({ pageContext }) => {
  const { file, wordpressPage } = useStaticQuery(graphql`
    query {
      file(name: { eq: "longWings" }) {
        publicURL
      }
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_templates {
            careerResponsibilitiesTitle
            careerRequirementsTitle
            careerPerksTitle
            careerNiceToHaveTitle
            careerLastCTASectionTitle
            careerLastCTASectionText
            careerLastCTASectionButtonText
            careerLastCTASectionButtonUrl
          }
        }
      }
    }
  `)
  const {
    careerResponsibilitiesTitle,
    careerRequirementsTitle,
    careerPerksTitle,
    careerNiceToHaveTitle,
    careerLastCTASectionTitle,
    careerLastCTASectionText,
    careerLastCTASectionButtonText,
    careerLastCTASectionButtonUrl,
  } = wordpressPage.cmb2.metabox_global_settings_templates
  const { title, metaboxes, featured_media, yoast_meta, path } = pageContext
  const {
    formSectionText,
    formSectionTitle,
    niceToHave,
    perks,
    requirements,
    responsibilities,
    salary,
  } = metaboxes
 
  return (
    <Layout marginTop="100px">
      <SEO meta={yoast_meta} title={title} path={path} />
      <Container size="medium">
        <BaseImg
          style={{ width: "100%", marginBottom: "0" }}
          localFile={featured_media.localFile}
          alt=""
        />
        <JobInfoBox>
          <h1>
            <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
          </h1>
          {salary}
        </JobInfoBox>
        <div
          style={{ margin: "20px", display: "flex", justifyContent: "center" }}
        >
          <ArrowMore />
        </div>
      </Container>
      <div style={{ position: "relative" }}>
        <WingsImage src={file.publicURL} alt="" />
        <WingsImage side="right" src={file.publicURL} alt="" />

        <Container size="small">
          <BorderedHeaderWrapper side="left" size="small">
            <BorderedHeader whiteSpace="nowrap">
              <BigMobileFont desktopFontSize="2.5rem">
                {careerRequirementsTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
          <StyledList>
            {requirements.map((requirement) => (
              <li key={requirement}>{requirement}</li>
            ))}
          </StyledList>

          <BorderedHeaderWrapper side="left" size="small">
            <BorderedHeader whiteSpace="nowrap">
              <BigMobileFont desktopFontSize="2.5rem">
                {careerNiceToHaveTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
          <StyledList>
            {niceToHave.map((element) => (
              <li key={element}>{element}</li>
            ))}
          </StyledList>

          <BorderedHeaderWrapper side="left" size="small">
            <BorderedHeader whiteSpace="nowrap">
              <BigMobileFont desktopFontSize="2.5rem">
                {careerResponsibilitiesTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
          <StyledList>
            {responsibilities.map((responsibility) => (
              <li key={responsibility}>{responsibility}</li>
            ))}
          </StyledList>

          <BorderedHeaderWrapper side="left" size="small">
            <BorderedHeader whiteSpace="nowrap">
              <BigMobileFont desktopFontSize="2.5rem">
                {careerPerksTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
          <StyledList>
            {perks.map((perk) => (
              <li key={perk}>{perk}</li>
            ))}
          </StyledList>
        </Container>
      </div>
      <Container size="medium">
        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">
            {formSectionTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <div
          style={{
            fontSize: "1.5rem",
            lineHeight: "1.25",
            textAlign: "center",
            margin: "40px 0",
          }}
        >
          {formSectionText}
        </div>
        <JobOfferForm />
      </Container>
      <LastCTASection
        title={careerLastCTASectionTitle}
        text={careerLastCTASectionText}
        buttonText={careerLastCTASectionButtonText}
        buttonUrl={careerLastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default SingleCareer
